import cx from 'classnames';
import React from 'react';
import Hero from "../../../../assets/images/maven/hero-big.png";
import Icon1 from "../../../../assets/images/maven/icon-1.svg";
import Icon2 from "../../../../assets/images/maven/icon-2.svg";
import Icon3 from "../../../../assets/images/maven/icon-3.svg";
import Icon4 from "../../../../assets/images/maven/icon-4.svg";
import Image1 from "../../../../assets/images/maven/image-1.png";
import Image2 from "../../../../assets/images/maven/image-2.png";
import Image3 from "../../../../assets/images/maven/image-3.png";
import Logo from "../../../../assets/images/maven/logo.svg";
import HeroMobile from "../../../../assets/images/maven/mobile-hero.png";
import useMixpanel from '../../../hooks/useMixpanel';
import useModal from '../../../hooks/useModal';
import * as styles from './Maven.module.scss';
import MavenModal from "./MavenModal";

const Maven = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const trackEvent = useMixpanel();

  const handleClick = (e) => {
    e.preventDefault();
    trackEvent("Clicked Sign Up");
    openModal();
  }

  return (
    <div className={cx("container", styles.container)}>
      <MavenModal isOpen={isOpen} closeModal={closeModal} />
      <section className={cx("section-container", styles.heroContainer)}>
        <div className={cx("section", styles.heroContent)}>
          <div className={cx("section-text-container", styles.heroTextContainer)}>
            <div className={styles.logoContainer}>
              <img src={Logo} alt="logo"/>
            </div>
            <div className={styles.heroTitle}>
              Turn your freelance down time into paid time.
            </div>
            <p>Fill the gaps in your schedule or between projects by connecting with clients who need on-demand expertise. Turn your quiet moments into an opportunity to earn with Maven.</p>
          </div>
          <div className={styles.heroImgSectionMobile}>
            <img src={HeroMobile} alt="freelancer" />
          </div>
          <div className={styles.heroImgSectionDesktop}>
            <div className={styles.heroImgContainer}>
              <img src={Hero} alt="freelancer"/>
            </div>
          </div>
        </div>
        <div className={styles.shapesContainer}>
          <div className={styles.shapeBlue} />
          <div className={styles.shapePink} />
        </div>
        <div className={styles.shapeContainerMobile}>
          <div className={styles.shapeYellowMobile} />
        </div>
      </section>
      <section className={styles.stepsContainer}>
        <div className={styles.steps}>
          <div className={styles.step}>
            <div className={styles.stepImgContainer}>
              <img src={Icon1} />
            </div>
            <h4>Set your rate</h4>
            <p>Outline your hourly rate or set up a package.</p>
          </div>
          <div className={styles.step}>
            <div className={styles.stepImgContainer}>
              <img src={Icon2} />
            </div>
            <h4>Go online</h4>
            <p>Show clients you're available to work.</p>
          </div>
          <div className={styles.step}>
            <div className={styles.stepImgContainer}>
              <img src={Icon3} />
            </div>
            <h4>Accept requests</h4>
            <p>Review on-demand project requests and connect with clients.</p>
          </div>
          <div className={styles.step}>
            <div className={styles.stepImgContainer}>
              <img src={Icon4} />
            </div>
            <h4>Get paid</h4>
            <p>Get paid for the time you spent working.</p>
          </div>
        </div>
        <button onClick={handleClick} className={styles.button}>Sign Up</button>
      </section>
      <section className={styles.bodyHeaderContainer}>
        <div className="mw">
          <h2>
            <strong>Maven</strong> is for Marketers, Graphic Designers, Software Engineers, Videographers, Product Managers, Copywriters, Photographers, <strong>and more…</strong>
          </h2>
        </div>

      </section>
      <section className={cx("section-container")}>
        <div className={cx("section", "section--reverse", styles.section1)}>
          <div className={styles.sectionImgContainer}>
            <img src={Image1} />
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3>Standout as part of a selective group.</h3>
            <p>At Maven, we rigorously vet every applicant to keep our talent network top-tier. By focusing on quality over quantity, we ensure only the best freelancers make the cut—so you can confidently set your rates to match the value of your work.</p>
          </div>
        </div>
      </section>
      <section className={cx("section-container")}>
        <div className={cx("section", styles.section2)}>
          <div className={styles.sectionImgContainer}>
            <img src={Image2} />
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3>Focus on your real work, not admin work.</h3>
            <p>Turn client hunting into earning with Maven. We fill your pipeline, qualify clients, and handle payouts, so you can focus on what you do best—delivering great work.</p>
          </div>
        </div>
      </section>
      <section className={cx("section-container")}>
        <div className={cx("section", "section--reverse", styles.section3)}>
          <div className={styles.sectionImgContainer}>
            <img src={Image3} />
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3>Effortlessly land new clients.</h3>
            <p>Top companies in tech, entertainment, and finance turn to Maven for quick access to the best talent. Expand your opportunities and connect with high-paying clients looking for experts like you.</p>
            <button onClick={handleClick} className={styles.button}>Sign Up</button>
          </div>
        </div>
      </section>
      <section className={cx("section-footer", styles.sectionFooter)}>
        <div className={cx("section-footer-content", styles.sectionFooterContent)}>
          <h2>Maven: where top talent meets top opportunities.</h2>
        </div>
      </section>
      <footer className={cx("footer")}>
        2024 Maven
      </footer>
    </div>
  )
}

export default Maven;